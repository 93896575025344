<div class="toasts">
  @for (toast of toasts; track toast) {
    <div [@toast] [ngClass]="{ good: toast.isGood, toast: !toast.component }">
      @if (toast.component) {
        <div>
          <ng-container *ngComponentOutlet="toast.component.comp; inputs: toast.component.inputs"></ng-container>
        </div>
      } @else {
        @if (!toast.hideIcon) {
          <lib-svg [svg]="toast.isGood ? 'fontawesome/check-circle' : 'times-circle'" class="icon white"></lib-svg>
        }
        <span data-source="prismic" [innerHTML]="toast.message" class="p-with-margin"></span>
      }
    </div>
  }
</div>
