import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, enableProdMode, ErrorHandler } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { HookParserEntry, provideDynamicHooks } from 'ngx-dynamic-hooks';
import { AnalyticsService } from 'analytics';
import { AuthInterceptor } from 'auth-data-access';
import { BRAND_CONFIG } from 'brand';
import { FeatureFlagService } from 'feature-flag';
import { LinkComponent } from 'link';
import { CustomErrorHandler, httpErrorInterceptor } from 'monitoring';
import { CmsService, PRISMIC_ENDPOINT } from 'prismic';
import { ProductService, SelfcareProductService } from 'product';
import { SPRINKLR_APP_ID } from 'sprinklr-webwidget';
import { TooltipComponent } from 'tooltip';
import {
  TranslateService,
  TRANSLATION_DYNAMIC_VALUES,
  TRANSLATION_PRISMIC_TAG,
  TranslationDynamicValues,
} from 'translate';
import {
  App,
  BrowserService,
  calcEnvironmentUrls,
  ENVIRONMENT_URLS_CONFIG_TOKEN,
  EnvironmentUrlsConfig,
  FALLBACK_LANGUAGE,
} from 'utils';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { environment } from './environments/environment';

const calcUrlsConfig = (browser: BrowserService): EnvironmentUrlsConfig => {
  return calcEnvironmentUrls(environment, browser.getOrigin());
};

const componentParsers: Array<HookParserEntry> = [
  { component: TooltipComponent },
  {
    component: LinkComponent,
    inputsWhitelist: ['link'],
  },
];

const initFeatureFlags = (featureFlagService: FeatureFlagService, analyticsService: AnalyticsService) => () =>
  featureFlagService.init(environment.featureFlagClientKey, data => analyticsService.addToDataLayer(data));

const translationDynamicValues: TranslationDynamicValues = {
  BRAND: environment.brand === 'lebara' ? 'Lebara' : environment.brand,
};

if (environment.productionMode) {
  enableProdMode();
}

function bootstrap() {
  bootstrapApplication(AppComponent, {
    providers: [
      provideAnimationsAsync(),
      provideRouter(
        appRoutes,
        withComponentInputBinding(),
        withRouterConfig({
          paramsInheritanceStrategy: 'always',
        })
      ),
      provideHttpClient(withInterceptorsFromDi(), withFetch(), withInterceptors([httpErrorInterceptor])),
      { provide: ErrorHandler, useClass: CustomErrorHandler },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: APP_ID, useValue: App.selfcare },
      { provide: TRANSLATION_DYNAMIC_VALUES, useValue: translationDynamicValues },
      { provide: TRANSLATION_PRISMIC_TAG, useValue: App.selfcare },
      { provide: ENVIRONMENT_URLS_CONFIG_TOKEN, useFactory: calcUrlsConfig, deps: [BrowserService] },
      { provide: BRAND_CONFIG, useValue: { brand: environment.brand } },
      { provide: FALLBACK_LANGUAGE, useValue: environment.fallbackLang },
      { provide: PRISMIC_ENDPOINT, useValue: environment.prismicEndpoint },
      {
        provide: APP_INITIALIZER,
        useFactory: (cmsService: CmsService, translateService: TranslateService) => async () => {
          await cmsService.init();
          return translateService.initialize();
        },
        multi: true,
        deps: [CmsService, TranslateService],
      },
      provideDynamicHooks(componentParsers),
      {
        provide: APP_INITIALIZER,
        useFactory: initFeatureFlags,
        multi: true,
        deps: [FeatureFlagService, AnalyticsService],
      },
      { provide: ProductService, useClass: SelfcareProductService },
      { provide: SPRINKLR_APP_ID, useValue: environment.sprinklerAppId },
    ],
  }).catch(err => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
