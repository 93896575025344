import { Injectable } from '@angular/core';
import { LinkField } from '@prismicio/client';
import { ProductCatalogService, RequestParams } from '@yol-digital/ms-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Promotion } from 'interfaces';
import { PCProduct } from './interfaces/pc-product.class';
import { ProductService } from './product.service';
import CatalogResponse = ProductCatalogService.CatalogResponse;
import PromotionResponse = ProductCatalogService.PromotionResponse;

@Injectable({
  providedIn: 'root',
})
export class SelfcareProductService extends ProductService {
  public getCatalog(query?: { code?: string }, params?: RequestParams) {
    return this.api.public.getCatalog(query, params);
  }

  public getPromotionByCode(): Observable<{ promotions: PromotionResponse[] }> {
    throw new Error('Method not implemented.');
  }

  public getPCProducts$(): Observable<CatalogResponse[]> {
    return this.observableDataLoader.get('ProductCatalog', () =>
      this.getCatalog().pipe(map((res: { plans: ProductCatalogService.CatalogResponse[] }) => res.plans))
    );
  }

  public getPCProductByCode(): Observable<CatalogResponse> {
    throw new Error('Method not implemented.');
  }

  public buildCheckoutPath(
    product: PCProduct,
    promotion?: Promotion,
    lineCheckId?: string
  ): { link: LinkField; queryParams?: object } {
    const queryParamsObj = this.buildCheckoutQueryParams(product, promotion, lineCheckId);
    const queryParamsArray = Object.entries(queryParamsObj);
    const queryParams = queryParamsArray.join('&').replace(/,/g, '=');

    return {
      link: {
        url: `${this.config.checkoutUrl}/${this.languageService.current}/start/${product.product_code}${
          queryParamsArray.length > 0 ? `?${queryParams}` : ''
        }`,
        link_type: 'Web',
      },
    };
  }
}
