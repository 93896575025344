<div class="flex flex-row items-center">
  @if (initials()) {
    <div
      class="h-28 w-28 border border-[--color-primary] rounded-full flex justify-center items-center mr-4 bg-[--color-gray-4-opacity] text-[--color-blue-darkened]"
    >
      <p class="font-bold text-3xl">{{ initials() }}</p>
    </div>
  } @else {
    @if (avatar()) {
      <img
        #avatarImage
        class="object-cover object-center w-24 h-24 rounded-full mr-4"
        [src]="avatar()"
        alt="profile image"
      />
    }
  }
  <ng-template #avatarImage>
    @if (avatar()) {
      <img
        #avatarImage
        class="object-cover object-center w-24 h-24 rounded-full mr-4"
        [src]="avatar()"
        alt="profile image"
      />
    }
  </ng-template>
  <div class="flex flex-col">
    @if (header) {
      <p class="line-clamp-1 font-thin text-4xl text-[--color-dark-gray]">{{ header() }}</p>
    }
    @if (description) {
      <p class="line-clamp-1 font-extralight text-xl text-[--color-dark-gray]">{{ description() }}</p>
    }
  </div>
</div>
