import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';

@Pipe({
  name: 'replaceToken',
  standalone: true,
  pure: false,
})
export class ReplaceTokenPipe implements PipeTransform {
  private translateService = inject(TranslateService);

  public transform(text: string, replacements: { [key: string]: string }): string {
    return this.translateService.replaceToken(text, replacements) as string;
  }
}
