import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, startWith, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteDataService {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  public getChildrenRouteData() {
    return toSignal(
      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        startWith(this.router),
        switchMap(() => {
          let currentRoute = this.route;
          while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
          }
          return currentRoute.data;
        })
      )
    );
  }
}
