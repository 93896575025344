import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AddressLookupResponse, ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig } from '../index';

@Injectable({ providedIn: 'root' })
export class AddressLookupService {
  private httpClient = inject(HttpClient);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private addressLookupBaseUrl = '/address-lookup';
  public addressFormValidations$ = new BehaviorSubject(false);

  findCities(postcode: string, isLineCheck = true): Observable<string[]> {
    if (!postcode || postcode.length !== 4) return of([]);

    let params;
    params = {
      postcode,
    };

    if (isLineCheck) {
      params = {
        ...params,
        lookupMethod: 'linecheck',
      };
    }

    const resp = this.httpClient
      .post<AddressLookupResponse>(`${this.config.newMicroServiceEndpoint}${this.addressLookupBaseUrl}/city`, params)
      .pipe(
        shareReplay(1),
        map(resp => resp.cities)
      );

    return resp;
  }

  findStreetNames(postcode: string, city: string, streetPrefix: string, isLineCheck = true): Observable<string[]> {
    if (!postcode || postcode.length !== 4) return undefined;

    let params;
    params = {
      postcode,
      city,
      streetName: streetPrefix,
    };

    if (isLineCheck) {
      params = {
        ...params,
        lookupMethod: 'linecheck',
      };
    }

    const resp = this.httpClient
      .post<AddressLookupResponse>(`${this.config.newMicroServiceEndpoint}${this.addressLookupBaseUrl}/street`, params)
      .pipe(
        shareReplay(1),
        map(resp => resp.streetNames)
      );

    return resp;
  }

  findStreetNumbers(
    postcode: string,
    city: string,
    streetName: string,
    streetNumberPrefix: string,
    isLineCheck = true
  ) {
    if (!postcode || postcode.length !== 4) return undefined;

    let params;
    params = {
      postcode,
      city,
      streetName,
      streetNumber: streetNumberPrefix,
    };

    if (isLineCheck) {
      params = {
        ...params,
        lookupMethod: 'linecheck',
      };
    }

    const resp = this.httpClient
      .post<AddressLookupResponse>(
        `${this.config.newMicroServiceEndpoint}${this.addressLookupBaseUrl}/street-number`,
        params
      )
      .pipe(
        shareReplay(1),
        map(resp => resp.streetNumbers)
      );
    return resp;
  }
}
