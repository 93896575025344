import { Routes } from '@angular/router';
import { featureFlagResolver } from 'feature-flag';
import { BaseLangGuard, langUrlMatcher } from 'language';
import { DummyComponent } from './pages/dummy/dummy.component';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./components/auth-selfcare/auth-selfcare.routes').then(m => m.routes) },
  { path: '', loadChildren: () => import('./pages/pages.routes').then(m => m.routes) },
];

export const appRoutes: Routes = [
  {
    matcher: langUrlMatcher,
    component: DummyComponent,
    resolve: { featureFlags: featureFlagResolver },
    children: routes,
  },
  {
    path: '**',
    component: DummyComponent,
    canActivate: [BaseLangGuard],
  },
];
