import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'lib-menu-profile',
  standalone: true,
  templateUrl: './menu-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class MenuProfileComponent {
  initials = input<string>();
  avatar = input<string>();
  header = input<string>();
  description = input<string>();
}
